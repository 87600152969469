import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { Actions, Select, Store, ofActionCompleted } from '@ngxs/store';
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { NotificationService } from 'src/app/core/services/notification.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { TableCellComponent, TableComponent, TableHeaderCellComponent, TableHeaderRowComponent, TableLoadingSpinner, TableRowComponent, TableRowGroupComponent } from 'src/app/shared/components/table/table.component';
import { MomentFormatPipeStandalone } from 'src/app/shared/pipes/moment-format.pipe-standalone';
import { PAG_MAX_RESULTS } from 'src/app/shared/utils/utils';
import { Recording } from '../../interfaces/recording.interface';
import { SecondsToTimePipe } from '../../pipes/seconds-to-time.pipe';
import { LoadRecordings, RemoveRecording } from '../../state/recordings.actions';
import { RecordingsState } from '../../state/recordings.state';
import { RecordingsFiltersComponent } from '../recordings-filters/recordings-filters.component';

@Component({
  selector: 'app-recordings-list',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    SecondsToTimePipe,
    MomentFormatPipeStandalone,
    RecordingsFiltersComponent,
    TableComponent,
    TableCellComponent,
    TableRowGroupComponent,
    TableLoadingSpinner,
    TableHeaderRowComponent,
    TableRowComponent,
    TableHeaderCellComponent
  ],
  templateUrl: './recordings-list.component.html'
})
export class RecordingsListComponent implements OnInit {

  tableHeaders: string[];
  pageSize: number;
  pageIndex: number;

  @Select(RecordingsState.recordings) recordings$: Observable<Recording[]>;
  @Select(RecordingsState.totalRecordings) totalCount$: Observable<number>;
  @Select(RecordingsState.loading) isLoading$: Observable<boolean>;
  @Select(RecordingsState.error) error$: Observable<boolean>;

  constructor(
    private store: Store,
    private actions$: Actions,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.tableHeaders = ["Receiver", "Caller", "Date", "Status", "Duration", ""];
    this.pageSize = PAG_MAX_RESULTS;
    this.pageIndex = 0;
  }

  ngOnInit(): void {
    this.dispatchLoadRecordings(0);
  }

  private dispatchLoadRecordings(SkipCount: number): void {
    this.store.dispatch(
      new LoadRecordings({
        SkipCount,
        MaxResultCount: this.pageSize
      })
    );
  }

  deleteRecording(recording: Recording): void {
    this.dialog.open(ConfirmDialogComponent, {
      width: "600px",
      data: {
        message: `You are about to delete a recording between ${recording.workerFullName} and ${recording.callerName}, it cannot be recovered. Are you sure?`,
        loadingText: "Deleting...",
        onConfirm: () => {
          this.onConfirmDelete(recording.id);
        }
      }
    });
  }
  
  private onConfirmDelete(recordingId: string): void {
    this.actions$.pipe(
      ofActionCompleted(RemoveRecording),
      take(1),
    ).subscribe(
      (response) => {
        if (response.result.successful) {
          this.notificationService.showSuccess("The recording was successfully deleted");
        } else if (response.result.error) {
          this.notificationService.showError("There was a problem deleting the recording. Please try again.");
        }
        this.dialog.closeAll();
      }
    );

    this.store.dispatch(
      new RemoveRecording(recordingId)
    );
  }

  getPage(pageEvent: PageEvent): void {
    this.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.dispatchLoadRecordings(this.pageIndex * this.pageSize);
  };

}
