import { AsyncPipe } from "@angular/common";
import { Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable } from 'rxjs';
import { Logout } from "src/app/features/users/state/users.actions";
import { AvatarComponent } from "src/app/shared/components/avatar/avatar.component";
import { PermissionStandaloneDirective } from "src/app/shared/directives/permission-standalone.directive";
import { Permission } from '../../enums/permission.enum';

@Component({
  selector: "app-user-select",
  standalone: true,
  imports: [RouterModule, MatMenuModule, MatIconModule, PermissionStandaloneDirective, AsyncPipe, AvatarComponent],
  templateUrl: "./user-select.component.html",
  styles: [`
    :host {
      top: 16px;
      right: 1.6rem;
    }`
  ]
})

export class UserSelectComponent {

  userRole: Permission;
  Permission: typeof Permission;
  @Select(state => state.users.auth.userFullName) fullName$: Observable<string>;
  
  constructor(private store: Store) {
    this.Permission = Permission;
  }
  
  logout(): void {
    this.store.dispatch(new Logout());
  }
}
